<template>
  <bc-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
    :attach="attach"
  >
    <template v-slot:activator="{ on, attrs }">
      <bc-text-field
        class="input-size"
        v-model="dateFormated"
        :label="$t('controlTower.pages.forecastSales.period')"
        append-icon="fa-calendar-alt"
        readonly
        v-bind="attrs"
        v-on="on"
        filled
      ></bc-text-field>
    </template>

    <bc-date-picker
      range
      v-model="dateRange"
      @input="input"
      :max="maxDate"
      :min="minDate"
    ></bc-date-picker>
  </bc-menu>
</template>

<script>
import moment from 'moment'

export default {
  name: 'DatepickerRange',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    minDate: {
      type: String,
      default: null
    },
    maxDate: {
      type: String,
      default: null
    },
    attach: {
      default: false
    }
  },
  data() {
    return {
      dateRange: [],
      menu: false
    }
  },
  mounted() {
    this.dateFormat = this.$t('application.dateFormat')
    this.dateRange = this.value
  },
  computed: {
    dateFormated() {
      return this.formatDate(this.dateRange)
    }
  },
  methods: {
    input(_data) {
      this.$emit('input', _data)
    },
    formatDate(date) {
      if (!date.length) return null
      if (!date) return null
      return [
        moment(this.dateRange[0]).format(this.dateFormat),
        this.dateRange[1] == null
          ? ''
          : moment(this.dateRange[1]).format(this.dateFormat)
      ]
    }
  },
  watch: {
    value(val) {
      this.dateRange = val
    }
  }
}
</script>
