<template>
  <div class="full-width-chart" ref="chartContainer">
    <highcharts
      v-if="chartInputData"
      :options="options"
      :highcharts="hcInstance"
      ref="chartRef"
      :updateArgs="[true, true, true]"
    ></highcharts>
  </div>
</template>

<script>
import Highcharts from 'highcharts'
import exporting from 'highcharts/modules/exporting'

exporting(Highcharts)

import { useNumberFormatter } from '../../utils/numberFormatter'

export default {
  name: 'HighChart',
  props: {
    chart: {
      type: Object
    },
    hasHighlightOnClick: {
      type: Boolean,
      default: false
    },
    chartInputData: {},
    isStacked: {
      type: Boolean,
      default: true
    },
    unity: {
      type: String,
      default: ''
    },
    newLayout: {
      type: Boolean,
      default: false
    },
    customOptions: {
      type: Object,
      default: () => ({})
    },
    exportSvg: {
      type: Boolean,
      default: false
    },
    isVerticalLabel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const vm = this
    return {
      hcInstance: Highcharts,
      oldDirectionX: 0,
      oldDirectionY: 0,
      keyPresses: {
        Control: false
      },
      options: {
        chart: {
          type: 'column',
          backgroundColor: 'transparent',
          panning: true,
          panKey: 'shift'
        },
        legend: {
          useHTML: true,
          symbolWidth: 0,
          symbolPadding: 0,
          symbolHeight: 0.1,
          labelFormatter: function () {
            const metaLabelHeight = this.initialType == 'line' ? '5px' : '15px'

            const isVisible =
              this.userOptions.visible == undefined || this.userOptions.visible
                ? true
                : false

            let color = this.color
            let disabled = ''
            if (!isVisible) {
              disabled = 'text-decoration: line-through;'
              color = 'darkgrey'
            }
            let name = this.name
            if (vm?.chart?.legend?.legendNameFormatter) {
              name = vm?.chart?.legend?.legendNameFormatter(this)
            }
            return (
              '<div style="display: flex; grid-gap: 5px; align-items: center"><div style="height: ' +
              metaLabelHeight +
              '; width:15px; background-color:' +
              color +
              '; display: block"></div>' +
              '<div style="' +
              disabled +
              '">' +
              name +
              '</div>'
            )
          },
          itemStyle: {
            color: '#959DB5',
            fontWeight: 'normal',
            textTransform: 'uppercase'
          }
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          column: {},
          bar: {},
          series: {
            groupPadding: 0,
            pointPadding: 0.1,
            borderWidth: 0,
            centerInCategory: true,
            marker: {},
            events: {}
          }
        },
        title: {
          text: ''
        },
        xAxis: {
          scrollbar: {
            enabled: true,
            barBackgroundColor: '#27BEE7',
            barBorderRadius: 1,
            barBorderWidth: 0,
            buttonBackgroundColor: 'transparent',
            buttonBorderWidth: 0,
            buttonBorderRadius: 1,
            trackBackgroundColor: '#B9B9B9',
            trackBorderWidth: 1,
            trackBorderRadius: 2,
            trackBorderColor: 'transparent',
            size: 5
          },
          categories: [],
          min: 0,
          labels: {
            style: {
              color: '#959DB5'
            },
            rotation: 0
          }
        },
        yAxis: {
          opposite: true,
          gridLineColor: '#616575',
          title: {
            text: ''
          },
          labels: {
            style: {
              color: '#959DB5'
            }
          },
          stackLabels: {
            enabled: true,
            align: 'center',
            crop: false,
            allowOverlap: true,
            rotation: 0,
            style: {
              fontSize: '12px',
              color: '#959DB5',
              textOutline: 'none'
            }
          }
        },
        series: []
      },
      formatter: useNumberFormatter(this)
    }
  },
  created() {
    this.setupOnClick()
  },
  mounted() {
    this.buildOptions()
    setTimeout(() => {
      this.$refs.chartRef.chart.reflow()
    }, 100)
  },
  watch: {
    exportSvg(value) {
      if (value) {
        this.onExportSvg()
      }
    },
    chart() {
      this.buildOptions()
    },
    chartInputData() {
      this.buildOptions()
    }
  },
  computed: {
    unitGraph() {
      return this.formatter.getChartViewUnit(this.chart)
    }
  },
  methods: {
    reflow() {
      setTimeout(() => {
        this.$refs.chartRef.chart.reflow()
      }, 100)
    },
    redraw() {
      setTimeout(() => {
        this.$refs.chartRef.chart.redraw()
      }, 100)
    },
    defineMax() {
      if (!this.chart) return null
      if (!this.chart.max) return null

      return this.chart.max - 1
    },
    onExportSvg() {
      const svg = this.$refs.chartRef.chart.getSVG()
      const imgSrc = 'data:image/svg+xml;base64,' + btoa(unescape(svg))
      this.$emit('onExportSvgData', imgSrc)
    },
    onGetExportSvg(options) {
      const svg = this.$refs.chartRef.chart
        .getSVG({
          chart: {
            width: options && options.w ? options.w : 1100,
            height: options && options.h ? options.h : 400,
            backgroundColor: 'white'
          }
        })
        .replaceAll('959DB5', '000')
        .replaceAll('616575', 'ddd')
        .replaceAll(
          'class="highcharts-scrollbar"',
          'class="highcharts-scrollbar" style="display:none"'
        )
      const imgSrc =
        'data:image/svg+xml;base64,' + btoa(unescape(encodeURI(svg)))
      return imgSrc
    },
    buildOptions() {
      const vm = this
      //categories
      this.options.series = []
      const categories = this.chartInputData.labels
      const max = this.defineMax()

      this.options.xAxis.categories = categories
      this.options.xAxis.max = max
      if (max == null) {
        this.options.xAxis.scrollbar = false
      } else {
        this.options.xAxis.max = Math.min(categories.length - 1, max)
      }

      this.options.yAxis.labels.formatter = (event) => {
        return formatCompact(event.value, vm.unitGraph)
      }

      let isStacked = false
      this.chartInputData.chartDataSets.forEach((_dataset) => {
        //series
        let serie = {
          id: _dataset.id,
          name: _dataset.name,
          data: _dataset.data,
          color: _dataset.defaultBackgroundColor,
          type: _dataset.type == 'bar' ? 'column' : _dataset.type,
          borderColor: 'transparent',
          states: {
            select: {}
          }
        }
        if (_dataset.type == 'horizontalBar') {
          serie.type = 'bar'
          this.options.chart.type = 'bar'
          this.options.yAxis.opposite = false

          this.options.plotOptions.series.dataLabels = {
            enabled: true,
            inside: false,
            crop: false,
            style: {
              fontSize: '12px',
              color: '#959DB5',
              textOutline: 'none'
            },
            align: 'left',
            allowOverlap: true,
            rotation: 0,
            formatter: function () {
              return formatCompactWithoutUnit(this.y, vm.unitGraph)
            }
          }
        }

        if (_dataset.stack) {
          serie.stack = _dataset.stack
        }

        if (_dataset.linkedTo) {
          serie.linkedTo = _dataset.linkedTo
        }

        if (serie.type == 'line') {
          serie.dashStyle = 'longdash'
          this.options.plotOptions.series.marker.enabled = false
        }

        if (serie.type === 'solidLine') {
          serie.type = 'line'
          serie.dashStyle = 'solid'
          serie.data = serie.data.map((x) => (x === null ? 0 : x))
          this.options.plotOptions.series.marker.enabled = true
          this.options.plotOptions.series.marker.radius = 3
        }

        if (this.hasHighlightOnClick && _dataset.type == 'bar') {
          serie.allowPointSelect = true
          serie.states.select.color = 'rgb(36, 187, 239)'
          const events = {
            events: {
              select: (_obj) => {
                const text = 'X'
                const chart = _obj.target.series.chart
                const point = event.point
                const series = chart.series[0]

                if (chart.drawX) {
                  chart.drawX.destroy()
                  chart.drawX = undefined
                }

                let x = chart.xAxis[0].toPixels(point.x) - chart.plotLeft + 10
                let y = chart.yAxis[0].toPixels(point.y) - chart.plotTop + 10

                setTimeout(() => {
                  chart.drawX = chart.renderer
                    .label(text, x, y, 10)
                    .css({
                      fontSize: '14pt',
                      color: 'white'
                    })
                    .add(series.group)
                }, 50)
              },
              unselect: (_obj) => {
                const chart = _obj.target.series.chart
                if (chart.drawX) chart.drawX.destroy()
                chart.drawX = undefined
              }
            }
          }

          serie.point = events
        }

        if (serie.type == 'solidgauge' || serie.type == 'gauge') {
          var spacings = {
            spacingTop: 0,
            spacingRight: 0,
            spacingBottom: 0,
            spacingLeft: 0
          }
          this.options.chart = { ...this.options.chart, ...spacings }

          this.options.chart.type = serie.type
          this.options.chart.backgroundColor = null
          this.options.chart.height = 225
          this.options.plotOptions.gauge = {
            dataLabels: {
              enabled: false
            }
          }

          serie.dial = {
            backgroundColor: '#FFFFFF',
            radius: '75%',
            borderColor: '#FFFFFF',
            borderWidth: 1,
            baseWidth: 5,
            topWidth: 0.1,
            baseLength: '0%', // of radius
            rearLength: '0%'
          }

          serie.pivot = {
            backgroundColor: 'transparent'
          }

          this.options.pane = {
            startAngle: -90,
            endAngle: 90,
            background: {
              backgroundColor: null,
              borderColor: null,
              innerRadius: '60%',
              outerRadius: '100%',
              shape: 'arc'
            }
          }

          this.options.yAxis = {
            min: 0,
            max: 100,
            tickLength: 0,
            tickColor: 'transparent',
            tickPositions: [25, 50, 75, 100],
            title: {
              text: ''
            },
            minorTickInterval: null,
            labels: {
              style: {
                color: 'black'
              },

              useHTML: true,
              formatter: (obj) => {
                switch (obj.value) {
                  case 25:
                    return (
                      '<span class="label-gauge" style="position:absolute;margin-left: -30px;margin-top: 13px; color: white">' +
                      obj.value +
                      '% </span>'
                    )
                  case 50:
                    return (
                      '<span class="label-gauge" style="position: absolute;margin-left: -38px;margin-top: -7px;">' +
                      obj.value +
                      '% </span>'
                    )
                  case 75:
                    return (
                      '<span class="label-gauge" style="position: absolute;margin-left: -29px;margin-top: -25px;">' +
                      obj.value +
                      '% </span>'
                    )
                  case 100:
                    return (
                      '<span class="label-gauge" style="position: absolute;margin-left: -17px;margin-top: -30px;">' +
                      obj.value +
                      '% </span>'
                    )
                }
              }
            },
            plotBands: [
              {
                from: 0,
                to: 25,
                color: '#D2354F',
                thickness: '50%'
              },
              {
                from: 25,
                to: 50,
                color: '#E2A13D',
                thickness: '50%'
              },
              {
                from: 50,
                to: 75,
                color: '#fffd38',
                thickness: '50%'
              },
              {
                from: 75,
                to: 100,
                color: '#89cd4d',
                thickness: '50%'
              }
            ]
          }
        }

        this.options.series.push(serie)
        if (_dataset.grouped) isStacked = true

        if (_dataset.type == 'bar' && !this.isStacked) {
          if (this.isVerticalLabel) {
            serie.dataLabels = {
              enabled: true,
              inside: false,
              align: 'left',
              y: -10,
              style: {
                fontSize: '12px',
                color: '#959DB5',
                textOutline: 'none'
              },
              crop: false,
              allowOverlap: true,
              rotation: 270,
              formatter: function () {
                return formatCompactWithoutUnit(this.y, vm.unitGraph)
              }
            }
          } else {
            serie.dataLabels = {
              enabled: true,
              inside: false,
              crop: false,
              style: {
                fontSize: '12px',
                color: '#959DB5',
                textOutline: 'none'
              },
              allowOverlap: true,
              rotation: 0,
              formatter: function () {
                return formatCompactWithoutUnit(this.y, vm.unitGraph)
              }
            }
          }
        }
      })
      const formatCompact = (value, unit) =>
        this.formatter.formatCompact(value, unit)
      const formatCompactWithoutUnit = (value, unit) =>
        this.formatter.formatCompactWithoutUnit(value, unit)

      if (!isStacked && this.isStacked) {
        this.isStackedFinal = true
        this.options.plotOptions.column.stacking = 'normal'
      }

      if (this.newLayout) {
        this.options.yAxis = {
          ...this.options.yAxis,
          tickAmount: 11,
          tickWidth: 1,
          tickLength: -50,
          showLastLabel: false,
          tickColor: this.options.yAxis.gridLineColor,
          offset: 50,
          labels: {
            ...this.options.yAxis.labels,
            y: -10,
            x: -10,
            align: 'right'
          },
          ...(this.customOptions.yAxis ?? {})
        }

        this.options.xAxis.labels.formatter = (event) => {
          if (typeof event.value != 'object') return event.value
          return event.value.join('<br/>')
        }

        this.options.xAxis.lineColor = this.options.yAxis.gridLineColor
        this.options.plotOptions.series.groupPadding = 0.075

        this.options.chart.events = {
          ...(this.options.chart.events || {}),
          render: function () {
            const circleRadius = 5
            const tickCount = 6
            const chart = this
            if (chart.customTickGroup) {
              chart.customTickGroup.destroy()
            }

            chart.yAxis[0].options.stackLabels.formatter = function () {
              return formatCompactWithoutUnit(this.total, vm.unitGraph)
            }

            chart.customTickGroup = chart.renderer
              .g('customTickGroup')
              .attr({ zIndex: 6 })
              .add()
            const plotRight = chart.plotLeft + chart.plotWidth + 50
            const plotTop = chart.plotTop
            const plotBottom = chart.plotTop + chart.plotHeight
            const tickSpacing = chart.plotHeight / (tickCount - 1)

            chart.renderer
              .path(['M', plotRight, plotTop, 'L', plotRight, plotBottom])
              .attr({ stroke: '#616575', 'stroke-width': 1 })
              .add(chart.customTickGroup)
            for (let i = 0; i < tickCount; i++) {
              chart.renderer
                .circle(plotRight, plotTop + tickSpacing * i, circleRadius)
                .attr({ fill: '#24bbef', zIndex: 1 })
                .add(chart.customTickGroup)
            }
          }
        }

        const format = (value) => this.formatter.format(value, 2)
        const valueTotal = (serie) => this.returnValueTotalTooltip(serie)
        this.options.tooltip = {
          formatter: function () {
            return `
              <strong>${this.x}</strong>
              <br/>
              <table>
                <tr>
                  <td>
                    <span style="color:${this.series.color}">●</span>
                    <span>${this.series.name}:</span>
                  </td>
                  <td>
                    <span>${format(this.y)}</span>
                    ${
                      this.percentage !== undefined
                        ? `<span style="color: #555">(${format(
                            this.percentage
                          )}%)</span>`
                        : ''
                    }
                  </td>
                </tr>
                ${valueTotal(this)}
                
              </table>
            `
          },
          useHTML: true
        }
      }

      this.reflow()
      this.redraw()
    },
    returnValueTotalTooltip(_tooltip) {
      if (
        _tooltip.series.initialType == 'column' &&
        this.formatter.format(_tooltip.total) !==
          this.formatter.format(_tooltip.y) &&
        this.isStackedFinal
      ) {
        return `<tr>
                  <td>
                    <span style="color:${_tooltip.series.color}">●</span>
                    <span>Total:</span>
                  </td>
                  <td>
                    <span>${this.formatter.format(_tooltip.total, 2)}</span>
                    
                  </td>
                </tr>`
      }
      return ''
    },
    setupOnClick() {
      this.options.plotOptions.series.cursor = 'pointer'
      const clickEvent = (event) => {
        this.$emit('onChartDataClick', { event })
      }
      this.options.plotOptions.series.events.click = clickEvent
    }
  }
}
</script>

<style lang="scss" scoped>
.offset {
  margin-top: -25px;
}
.full-width-chart {
  width: 100%;
}
::v-deep .highcharts-exporting-group {
  display: none;
}
::v-deep .label-gauge {
  color: var(--supply_content_-262c39);
  text-align: right;
  font: normal normal normal 12px/15px Aller;
  letter-spacing: 0px;
  color: #262c39;
}
</style>
