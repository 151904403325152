const splitDecimal = (value, orderOfMagnitude) => {
  let [whole, decimal] = (Math.floor((value * 10) / orderOfMagnitude) / 10)
    .toFixed(1)
    .split('.')
  return decimal === '0' ? [whole] : [whole, decimal]
}

const reverse = (string) => {
  let reversedString = ''
  for (let i = string.length - 1; i >= 0; i--) {
    reversedString += string[i]
  }
  return reversedString
}

/**
 * Format number to a compact string representation
 *
 * @param {number} value Value to be formatted
 * @param {string} separator Decimal separator character
 * @param {string} unit Unit that will be used if it exists
 * @returns A formated number
 */
export const formatNumberCompact = (value, separator, unit) => {
  const rawValue = !value && value !== 0 ? NaN : Number(value)
  let numberParts = []
  let strUnit = ''

  if (Number.isNaN(rawValue)) {
    return '—'
  }

  if (!unit) {
    if (rawValue < 1000) {
      numberParts = splitDecimal(rawValue, 1)
      strUnit = ''
    } else if (rawValue < 1000000) {
      numberParts = splitDecimal(rawValue, 1000)
      strUnit = ' K'
    } else if (rawValue < 1000000000) {
      numberParts = splitDecimal(rawValue, 1000000)
      strUnit = ' M'
    } else if (rawValue < 1000000000000) {
      numberParts = splitDecimal(rawValue, 1000000000)
      strUnit = ' B'
    }
  } else {
    if (unit == 'U') {
      numberParts = splitDecimal(rawValue, 1)
      strUnit = ''
    } else if (unit == 'K') {
      numberParts = splitDecimal(rawValue, 1000)
      strUnit = ' K'
    } else if (unit == 'M') {
      numberParts = splitDecimal(rawValue, 1000000)
      strUnit = ' M'
    } else if (unit == 'B') {
      numberParts = splitDecimal(rawValue, 1000000000)
      strUnit = ' B'
    }
  }

  return numberParts.join(separator) + strUnit
}

/**
 * Format number to a compact string representation without Unit
 *
 * @param {number} value Value to be formatted
 * @param {string} separator Decimal separator character
 * @param {string} unit Unit that will be used if it exists
 * @returns A formated number
 */

export const formatNumberCompactWithoutUnit = (value, separator, unit) => {
  const rawValue = !value && value !== 0 ? NaN : Number(value)
  let numberParts = []
  if (Number.isNaN(rawValue)) {
    return '—'
  }

  if (!unit) {
    if (rawValue < 1000) {
      numberParts = splitDecimal(rawValue, 1)
    } else if (rawValue < 1000000) {
      numberParts = splitDecimal(rawValue, 1000)
    } else if (rawValue < 1000000000) {
      numberParts = splitDecimal(rawValue, 1000000)
    } else if (rawValue < 1000000000000) {
      numberParts = splitDecimal(rawValue, 1000000000)
    }
  } else {
    if (unit == 'U') {
      numberParts = splitDecimal(rawValue, 1)
    } else if (unit == 'K') {
      numberParts = splitDecimal(rawValue, 1000)
    } else if (unit == 'M') {
      numberParts = splitDecimal(rawValue, 1000000)
    } else if (unit == 'B') {
      numberParts = splitDecimal(rawValue, 1000000000)
    }
  }

  return numberParts.join(separator)
}

/**
 * Get average unit of the graph
 *
 * @param _chart Chart to be calculed
 * @returns Average chart unit. Possible Values: U, K, M, B
 */
export const getChartViewUnit = (_chart) => {
  var countByColPot = getTotalValueColumns(_chart)
  var listOfUnit = getCountByColumnPotency(countByColPot)
  var unit = GetKeyTopListValue(listOfUnit)
  return unit
}

/**
 * Get key from top list value
 *
 * @param List with unit values
 * @returns A top list value. Possible Values: U, K, M, B
 */
export const GetKeyTopListValue = (_list) => {
  var keyTopValue = ''
  for (let key in _list) {
    if (_list[key] >= _list[keyTopValue] || keyTopValue === '') {
      keyTopValue = key
    }
  }
  return keyTopValue
}

/**
 * Get list of column potency
 *
 * @param _chart Chart to be calculed
 * @returns A list of potency ​​by columns. Order: U, K, M, B
 */
export const getListOfColumnPotency = (_chart) => {
  var potencyColumns = {}
  _chart.series.forEach((serie) => {
    serie.data.forEach(function callback(value, index) {
      if (!(index in potencyColumns)) potencyColumns[index] = 0

      var potency
      if (value < 1000) {
        potency = 'U'
      } else if (value < 1000000) {
        potency = 'K'
      } else if (value < 1000000000) {
        potency = 'M'
      } else {
        potency = 'B'
      }
      potencyColumns[index] = potency
    })
  })
  return potencyColumns
}

/**
 * Get list of count column potency
 *
 * @param _chart Chart to be calculed
 * @returns A list of count potency ​columns. Order: U, K, M, B
 */
export const getCountByColumnPotency = (listOfUnit) => {
  var potencyColumns = { U: 0, K: 0, M: 0, B: 0 }
  for (var key in listOfUnit) {
    let value = listOfUnit[key]
    if (value != null && value > 0) {
      if (value < 1000) {
        potencyColumns['U'] += 1
      } else if (value < 1000000) {
        potencyColumns['K'] += 1
      } else if (value < 1000000000) {
        potencyColumns['M'] += 1
      } else {
        potencyColumns['B'] += 1
      }
    }
  }
  return potencyColumns
}

/**
 * Get list of column total values
 *
 * @param _chart Chart to be calculed
 * @returns A list of total values ​​by columns
 */
export const getTotalValueColumns = (_chart) => {
  var totalTooltipColumns = {}
  _chart.series.forEach((serie) => {
    if (serie.grouped) {
      serie.data.forEach(function callback(value, index) {
        if (!(index in totalTooltipColumns)) totalTooltipColumns[index] = 0
        if (value && totalTooltipColumns[index] < value)
          totalTooltipColumns[index] = value ?? 0
      })
    } else {
      serie.data.forEach(function callback(value, index) {
        if (!(index in totalTooltipColumns)) totalTooltipColumns[index] = 0
        totalTooltipColumns[index] += value ?? 0
      })
    }
  })
  return totalTooltipColumns
}

/**
 * Get list of total values ​​by bar columns
 *
 * @param _chart Chart to be calculed
 * @returns A list of total values ​​by bar columns
 */
export const getTotalValueColumnsOnlyBars = (_chart) => {
  var totalTooltipColumns = {}
  _chart.series.forEach((serie) => {
    if (serie.type === 'bar')
      serie.data.forEach(function callback(value, index) {
        if (!(index in totalTooltipColumns)) totalTooltipColumns[index] = 0
        totalTooltipColumns[index] += value
      })
  })
  return totalTooltipColumns
}

/**
 * Format number to a localized string representation
 *
 * @param {number} value Value to be formatted
 * @param {number} decimals Number of decimal cases
 * @param {string} thousandsSeparator Thousands separator character
 * @param {string} decimalSeparator Decimal separator character
 * @returns A formated number
 */
export const formatNumber = (
  value,
  decimals = 2,
  thousandsSeparator = ',',
  decimalSeparator = '.'
) => {
  const rawValue = !value && value !== 0 ? NaN : Number(value)
  let [whole, decimal] = rawValue.toFixed(decimals).split('.')
  whole = reverse(
    reverse(whole).replace(/(\d{3})(?=\d)/g, `$1${thousandsSeparator}`)
  )
  return `${whole}${decimalSeparator}${decimal}`
}

/**
 * Number formatter composable
 * @param {*} i18n i18n instance
 * @returns A number formatter
 */
export const useNumberFormatter = (i18n) => {
  /**
   * Format number to a localized string representation
   *
   * @param {number} value Number to be formatted
   * @param {number} decimals Number of decimal cases
   * @returns Formatted number
   */
  const format = (value, decimals = 2) =>
    formatNumber(
      value,
      decimals,
      i18n.$t('application.thousandsSeparator'),
      i18n.$t('application.decimalSeparator')
    )

  /**
   * Format number to a compact string representation
   *
   * @param {number} value Number to be formatted
   * @returns Formatted number
   */
  const formatCompact = (value, unit) =>
    formatNumberCompact(value, i18n.$t('application.decimalSeparator'), unit)

  const formatCompactWithoutUnit = (value, unit) =>
    formatNumberCompactWithoutUnit(
      value,
      i18n.$t('application.decimalSeparator'),
      unit
    )

  return {
    format,
    formatCompact,
    formatCompactWithoutUnit,
    getTotalValueColumns,
    getChartViewUnit
  }
}
